@font-face {
  font-family: "HelveticaCondensed";
  src: url("#{$fonts-path}/helvetica-condensed.woff");
  src: local("☺"),
  url("#{$fonts-path}/helvetica-condensed.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaCondensed";
  src: url("#{$fonts-path}/helvetica-condensedBold.woff");
  src: local("☺"),
  url("#{$fonts-path}/helvetica-condensedBold.woff");
  font-style: normal;
  font-weight: bold;
}

// Montserrat Fonts
// @font-face {
//   font-family: "Montserrat";
//   src: url("#{$fonts-path}/Montserrat-Regular.otf");
//   src: local("☺"),
//   url("#{$fonts-path}/Montserrat-Regular.otf") format("truetype");
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Montserrat";
//   src: url("#{$fonts-path}/Montserrat-SemiBold.otf");
//   src: local("☺"),
//   url("#{$fonts-path}/Montserrat-SemiBold.otf") format("truetype");
//   font-weight: 600;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Montserrat";
//   src: url("#{$fonts-path}/Montserrat-Bold.otf");
//   src: local("☺"),
//   url("#{$fonts-path}/Montserrat-Bold.otf") format("truetype");
//   font-weight: 700;
//   font-style: normal;
// }