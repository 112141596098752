.dualGrah{
    display: flex; 
    flex-direction: column;
}
.dualHilo-table {
    margin: 0 100px;
    @media #{$breaketpoint-mobile}{
        margin: 0px 0px;
    }
}
.statisticsTables{
    padding: 0 100px;
    @media #{$breaketpoint-mobile}{
        padding: 0px !important;
    }
}
.statisticsTables {
    thead th {
      padding: 8px 7px;
      @media #{$breaketpoint-mobile}{
        padding: 8px 4px;
    }
    }
    th:nth-child(1) {
      text-align: left;
    }
    tbody
    td {
      height: 35px;
    }
    padding: 20px 100px;
    td:nth-child(1) {
      text-align: left;
    }
  }
  .resultsData{
    @media #{$breaketpoint-mobile}{
      height:80px;
      .status{
        height: 12px;
        width: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  }
  
  }