  .keyIndicators {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 40px;
    justify-content: space-between;
    @media #{$breaketpoint-mobile}{
      padding: 1px 5px;
      }
    .text {
      font-weight: 600;
    }
    .rank {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      border: solid 1px #555;
      display: flex;
      font-weight: 600;
      align-items: center;
      justify-content: center;
      background-color: #ffcc00;
      color: $black;
    }
    &.home-bg,.away-bg {
      color: $black;
      padding: 5px 40px;
      justify-content: space-between;
      @media #{$breaketpoint-mobile}{
        padding: 1px 5px;
        }
    }
    &.home-bg {
      background-color: #29d9d7;
      border-radius: 0 5px 5px 0;
     
    }
    &.away-bg {
      background-color: #f17f80;
      border-radius: 5px 0 0 5px;
    }
  }
  .home-text {
    color: $homeTeamColor;
  }
  .away-text {
    color: $awayTeamColor;
  }
  .w-140{
    @media #{$breaketpoint-mobile}{
      width:250px
      }
  }
  .home-width{
    @media #{$breaketpoint-mobile}{
    width: 140px;
  }
}
  .away-width{
    @media #{$breaketpoint-mobile}{
    width: 140px;
  }
}
.team {
  @media #{$breaketpoint-mobile}{
    text-align: left !important;
  }
}
.seasonalstate-clr-white{
  display: flex;
  line-height: 30px;
  flex-direction: column;
  color: #fff;
  margin: 3px 0 0;
  background-color: #27497e;
  padding-left: 10px;
}