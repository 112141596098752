.standingTable-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 10px 20px;
  @media #{$breaketpoint-mobile} {
    padding: 0px;
  }
  .standingTable-group {
    display: flex;
    flex-direction: row;
    width: 644px;
    margin: 5px 0px 0;
  }
}
.tableDefault.standingsTable {
  tbody td:first-child {
    text-align: left;
  }
  thead th {
    background-color: #052754;
    color: #fff;

    &:first-child {
      text-align: left;
    }
    
  }
  .groupHeading {
    font-size: 20px;
    font-weight: 600;
    @media #{$breaketpoint-mobile} {
      font-size: 14px;
    }
  }
  .teamGroups{
    width: 140px;
  }
}