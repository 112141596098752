body {
	margin: 0px;
	padding: 0px;
	font-family: "Montserrat", sans-serif;
	font-size: 14px;
	line-height: 1.5em;
	background-color: $body-bg;
	color: $body-txtColor;
	-webkit-font-smoothing: auto;
	@media #{$breaketpoint-mobile}{
		font-size: 12px;
		font-weight: 500;
		
      }
}
// .background-image {
// 	// background-image: url(../images/backgrounds-football-2.jpg);
// 	background-size: cover;
// 	background-color: $body-bg;
// }

// .main-transparenrbg {
// 	filter: alpha(opacity=80);
// 	-moz-opacity: 0.8;
// 	-khtml-opacity: 0.8;
// 	opacity: 0.8;
// }

ul,
ol {
	margin: 0;
	padding: 0;
	li {
		list-style: none;
	}
}
a,a:hover {
	text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

img {
	vertical-align: middle;
	border-style: none;
	max-width: 100%;
}
.text-right{
	text-align: right !important;
}
.text-left{
	text-align: left !important;
}
.text-center{
	text-align: center !important;
}
.text-justify{
	text-align: justify !important;
}
.v-hidden{
	visibility: hidden !important;
}
.d-none{
	display: none !important;
}
.d-block {
	display: block !important;
}
.cursor-pointer{
	cursor: pointer;
}
.truncate{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
	white-space: nowrap;
}
.space-5{
    margin: 5px auto;
	padding: 2px 0;
	@media #{$breaketpoint-mobile}{
		margin: 0px auto;
	}
}
.space-10{
	margin: 10px auto;
    padding: 5px 0;
}
.p-0{
	padding: 0 !important;
}
.m-0{
	margin: 0 !important;
}
.mt-10{
	margin-top: 5px;
}
.mt-20{
    margin-top: 20px;
}
.mr-10{
    margin-right: 10px;
}
.ml-10{
    margin-left: 10px;
}
.mb-20{
	margin-bottom: 20px;
}
.w-100{
	width:100% !important;
}
.p-lr0
{
	padding: 5px 0 !important;
}
.w-70{
	width: 70px;
}
.w-65{
	width: 65px;
}
.width-event{
	width: 43px;
}
.w-80{
	width: 80px;
}
.w-30{
	width: 30px;
}
.w-50{
	width: 50px;
}
.w-100px{
	width: 100px;
	@media #{$breaketpoint-mobile}{
		width: 65px;
		}
}
.w-135{
	width: 135px;
	@media #{$breaketpoint-mobile}{
		width: 135px;
		}
}
.w-150{
    width: 150px;
}
.w-date {
	width: 180px;
	@media #{$breaketpoint-mobile}{
		width: 105px;
		div{
			margin-right: 5px;
		}
		}
}
.w-form {
	width: 180px;
	@media #{$breaketpoint-mobile}{
		width: 100px;
		
		}
}
.w-fixture {
    width: 150px;
	@media #{$breaketpoint-mobile}{
		 width: 150px;
		
		}
}
.w-winPredictor {
	width: 210px;
	@media #{$breaketpoint-mobile}{
		width: 70px;
		
		}
}
.w-bestBet {
    width: 150px;
}
.w-koTime{
	width: 65px;
	@media #{$breaketpoint-mobile}{
		width: 70px;
		
		}
}