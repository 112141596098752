// Brand colors
$primaryColor: #001A69;
$secondaryColor: #052754;
$tertiaryColor: #ff6f29;

// Common colors
$white: #fff;
$black: #000;

$dark-blue: #022069;

//Common body
$body-bg: #ECECEC;
$body-txtColor: #222;
$body-borderRadius: 4px;
$borderColor: $black;

// min and max width of layout
// $page__maxWidth: 1360px;
// $page__minWidth: 1360px;

// Project name
$project-name:".HKJC-stats-center";

// Paths
$fonts-path: "../../fonts";
$flag-path: "../../images";

// 2 column layout with 50% each
// $left-panel50:50%;
// $right-panel50:50%;

// icon background colors
$win:#ff0000;
$loss:#22aa4c;
$draw:#ffa500;
$high:#74aa8c;
$low:#ebbf32;
$BTTSno:#ebbf32;
$BTTSyes:#74aa8c;
$winpercent:#22aa4c;
$losspercent:#ff0000;
$drawpercent:#ffa500;

// box shadow effect
$boxshadow:2px 2px 2px #ddd;

// oddsDetails
$homeTeamColor:#09b3c6;
$drawTeamColor:#ffffff;
$awayTeamColor:#f38182;

// Strength meter colors
$color1:#ffcc00;
$color2:#dac045;
$color3:#47bba3;
$color4:#f65d57;
$color5:#f90;
$color6:#6dc36d;
$color7:#0099ff;
$color8:#6851df;
$color9:#777;

// $orange:#ff9900;
// $green:#00cc66;
// $maroon:#cc6666;
// $purple:#cc00cc;
// $lime:#ffcc00;
// $pink:#ff80bf;
// $lightGreen:#99ff99;
// $lightBlue:#0099ff;
// $lightBrown:#999966;
//media queries

$breaketpoint-minimobile: "(max-width: 767px)";
$breaketpoint-mobile: "(max-width: 767px)";

