.layout-footer {
	background-color: $black;
	@media #{$breaketpoint-mobile}{
		background-color: #022069;
		border: solid 1px #4b6e9f;
	  }
	.footerBottom {
		display: flex;
		flex-direction: column;
		align-items: center;
		color: rgb(163, 162, 162);
		justify-content: center;
		padding-top: 10px;
		@media #{$breaketpoint-mobile}{
			text-align: center;
			margin: 0 30px;
		  }
	}
	.footer-linksList {
		ul {
			li {
				display: inline-block;
				a {
					color: #ddd;
					font-size: 12px;
					padding: 10px;
					@media #{$breaketpoint-mobile}{
						font-size: 10px;
						padding: 3px;
					  }
					&:hover {
						color: $white;
					}
				}
			}
			li:not(:last-child):after {
				content: "|";
			}
		}
	}
	.footer-copyRight {
		color: #ddd;
		font-size: 11px;
		padding: 10px 0;
		@media #{$breaketpoint-mobile}{
			font-size: 10px;
			padding: 3px;
		  }
	}
}